// // pages/Packages.js
// import React, { useState, useEffect } from "react";
// import { Card, Button, Container, Row, Col } from "react-bootstrap";
// import axios from "axios";

// const Packages = () => {
//   const [packages, setPackages] = useState([]);

//   useEffect(() => {
//     axios
//       .get("http://localhost:8000/api/packages")
//       .then((response) => setPackages(response.data))
//       .catch((error) => console.error("Error fetching packages:", error));
//   }, []);

//   return (
//     <Container fluid>
//       <h1 className="my-4">Travel Packages</h1>
//       <Button variant="primary" className="mb-3">
//         Add Package
//       </Button>
//       <Row>
//         {packages.map((pkg) => (
//           <Col md={4} key={pkg.id}>
//             <Card className="mb-4">
//               <Card.Body>
//                 <Card.destinations>{pkg.destinations}</Card.destinations>
//                 <Card.Text>{pkg.description}</Card.Text>
//                 <Button variant="warning" className="me-2">
//                   Edit
//                 </Button>
//                 <Button variant="danger">Delete</Button>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </Container>
//   );
// };

// export default Packages;

import React, { useState, useEffect } from "react";
import { Form, Button, Container, Table, Modal } from "react-bootstrap";
import axios from "axios";
const url = process.env.REACT_APP_URL;

const PackagesCard = () => {
  const [blogs, setBlogs] = useState([]);
  const [formData, setFormData] = useState({
    destinations: "",
    place: "",
    duration: "",
    amountPerCouple: "",
    image: null,
  });
  const [editingId, setEditingId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch all blogs on component mount
  useEffect(() => {
    fetchBlogs();
  }, []);

  // Fetch all blogs
  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${url}/api/package/getAll`);
      setBlogs(response.data.results);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle image upload
  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };

  // Submit form (Create/Upduration)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("destinations", formData.destinations);
    data.append("place", formData.place);
    data.append("duration", formData.duration);
    data.append("amountPerCouple", formData.amountPerCouple);
    if (formData.image) data.append("image", formData.image);

    try {
      if (editingId) {
        await axios.put(`${url}/api/package/update/${editingId}`, data);
      } else {
        await axios.post(`${url}/api/package/create`, data);
      }
      fetchBlogs();
      clearForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setShowModal(false);
  };

  // Clear form and reset state
  const clearForm = () => {
    setFormData({
      destinations: "",
      place: "",
      duration: "",
      amountPerCouple: "",
      image: null,
    });
    setEditingId(null);
  };

  // Handle edit
  const handleEdit = (blog) => {
    setFormData({
      destinations: blog.destinations,
      place: blog.place,
      duration: blog.duration,
      amountPerCouple: blog.amountPerCouple,
      image: null,
    });
    setEditingId(blog.id);
    setShowModal(true);
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${url}/api/package/delete/${id}`);
      fetchBlogs();
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  return (
    <Container className="mt-5">
      <h3>Package Management</h3>
      <Button
        variant="primary"
        onClick={() => setShowModal(true)}
        className="mb-4"
      >
        Add Blog
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>destinations</th>
            <th>place</th>
            <th>duration</th>
            <th>amountPerCouple</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {blogs.map((blog) => (
            <tr key={blog.id}>
              <td>{blog.destinations}</td>
              <td>{blog.place}</td>
              <td>{blog.duration}</td>
              <td>{blog.amountPerCouple.slice(0, 50)}...</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleEdit(blog)}
                  className="me-2"
                >
                  Edit
                </Button>
                <Button variant="danger" onClick={() => handleDelete(blog.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Add/Edit Blog */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          {editingId ? "Edit Blog" : "Add Blog"}
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formdestinations">
              <Form.Label>destinations</Form.Label>
              <Form.Control
                type="text"
                name="destinations"
                value={formData.destinations}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formplace" className="mt-3">
              <Form.Label>place</Form.Label>
              <Form.Control
                type="text"
                name="place"
                value={formData.place}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formduration" className="mt-3">
              <Form.Label>duration</Form.Label>
              <Form.Control
                type="duration"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formamountPerCouple" className="mt-3">
              <Form.Label>amountPerCouple</Form.Label>
              <Form.Control
                as="textarea"
                name="amountPerCouple"
                value={formData.amountPerCouple}
                onChange={handleChange}
                rows={3}
                required
              />
            </Form.Group>

            <Form.Group controlId="formImage" className="mt-3">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleFileChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              {editingId ? "Upduration" : "Submit"}
            </Button>
            <Button
              variant="secondary"
              onClick={clearForm}
              className="mt-3 ms-2"
            >
              Clear
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default PackagesCard;
