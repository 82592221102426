import React, { useState, useEffect } from "react";
import { Form, Button, Container, Table, Modal } from "react-bootstrap";
import axios from "axios";
const url = process.env.REACT_APP_URL;

const BlogAdmin = () => {
  const [blogs, setBlogs] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    author: "",
    date: "",
    content: "",
    image: null,
  });
  const [editingId, setEditingId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch all blogs on component mount
  useEffect(() => {
    fetchBlogs();
  }, []);

  // Fetch all blogs
  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        `${url}/api/blogs/getAll`
      );
      setBlogs(response.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle image upload
  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };

  // Submit form (Create/Update)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", formData.title);
    data.append("author", formData.author);
    data.append("date", formData.date);
    data.append("content", formData.content);
    if (formData.image) data.append("image", formData.image);

    try {
      if (editingId) {
        await axios.put(
          `${url}/api/blogs/update/${editingId}`,
          data
        );
      } else {
        await axios.post(`${url}/api/blogs/post`, data);
      }
      fetchBlogs();
      clearForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setShowModal(false);
  };

  // Clear form and reset state
  const clearForm = () => {
    setFormData({
      title: "",
      author: "",
      date: "",
      content: "",
      image: null,
    });
    setEditingId(null);
  };

  // Handle edit
  const handleEdit = (blog) => {
    setFormData({
      title: blog.title,
      author: blog.author,
      date: blog.date,
      content: blog.content,
      image: null,
    });
    setEditingId(blog.id);
    setShowModal(true);
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${url}/api/blogs/delete/${id}`);
      fetchBlogs();
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  return (
    <Container className="mt-5">
      <h3>Blog Management</h3>
      <Button
        variant="primary"
        onClick={() => setShowModal(true)}
        className="mb-4"
      >
        Add Blog
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Author</th>
            <th>Date</th>
            <th>Content</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {blogs.map((blog) => (
            <tr key={blog.id}>
              <td>{blog.title}</td>
              <td>{blog.author}</td>
              <td>{blog.date}</td>
              <td>{blog.content.slice(0, 50)}...</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleEdit(blog)}
                  className="me-2"
                >
                  Edit
                </Button>
                <Button variant="danger" onClick={() => handleDelete(blog.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Add/Edit Blog */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editingId ? "Edit Blog" : "Add Blog"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAuthor" className="mt-3">
              <Form.Label>Author</Form.Label>
              <Form.Control
                type="text"
                name="author"
                value={formData.author}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDate" className="mt-3">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formContent" className="mt-3">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                name="content"
                value={formData.content}
                onChange={handleChange}
                rows={3}
                required
              />
            </Form.Group>

            <Form.Group controlId="formImage" className="mt-3">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleFileChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              {editingId ? "Update" : "Submit"}
            </Button>
            <Button
              variant="secondary"
              onClick={clearForm}
              className="mt-3 ms-2"
            >
              Clear
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default BlogAdmin;
