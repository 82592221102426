import React, { useState, useEffect } from "react";
import { Form, Button, Container, Table, Modal } from "react-bootstrap";
import axios from "axios";

const url = process.env.REACT_APP_URL;

const TravelPackageAdmin = () => {
  const [packages, setPackages] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    destinations: "",
    packageType: "",
    place: "",
    amountPerCouple: "",
    amountPerAdult: "",
    amountPerChild: "",
    duration: "",
    overview: "",
    itinerary: "",
    inclusion: "",
    exclusion: "",
    pickupPoint: "", // Added field
    dropPoint: "", // Added field
    spotType: "", // Added field
    avgRating: "", // Added field
    photos: null,
  });
  const [editingId, setEditingId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch all packages on component mount
  useEffect(() => {
    fetchPackages();
  }, []);

  // Fetch all travel packages
  const fetchPackages = async () => {
    try {
      const response = await axios.get(`${url}/api/package/getAll`);
      setPackages(response.data.results); // Adjust based on actual API structure
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle photo upload
  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      photos: e.target.files,
    }));
  };

  // Submit form (Create/Update)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();

    // Ensure all fields are added to the FormData
    Object.keys(formData).forEach((key) => {
      if (key === "photos" && formData.photos) {
        Array.from(formData.photos).forEach((photo) =>
          data.append("photos", photo)
        );
      } else {
        data.append(key, formData[key] || ""); // Ensure even empty fields are appended
      }
    });

    try {
      if (editingId) {
        await axios.put(`${url}/api/package/update/${editingId}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        await axios.post(`${url}/api/package/post`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
      fetchPackages();
      clearForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setShowModal(false);
  };

  // Clear form and reset state
  const clearForm = () => {
    setFormData({
      title: "",
      destinations: "",
      packageType: "",
      place: "",
      amountPerCouple: "",
      amountPerAdult: "",
      amountPerChild: "",
      duration: "",
      overview: "",
      itinerary: "",
      inclusion: "",
      exclusion: "",
      pickupPoint: "", // Reset added field
      dropPoint: "", // Reset added field
      spotType: "", // Reset added field
      avgRating: "", // Reset added field
      photos: null,
    });
    setEditingId(null);
  };

  // Handle edit
  const handleEdit = (travelPackage) => {
    setFormData({
      title: travelPackage.title,
      destinations: travelPackage.destinations,
      packageType: travelPackage.packageType,
      place: travelPackage.place,
      amountPerCouple: travelPackage.amountPerCouple,
      amountPerAdult: travelPackage.amountPerAdult,
      amountPerChild: travelPackage.amountPerChild,
      duration: travelPackage.duration,
      overview: travelPackage.overview,
      itinerary: travelPackage.itinerary,
      inclusion: travelPackage.inclusion,
      exclusion: travelPackage.exclusion,
      pickupPoint: travelPackage.pickupPoint,
      dropPoint: travelPackage.dropPoint,
      spotType: travelPackage.spotType,
      avgRating: travelPackage.avgRating,
      photos: null,
    });
    setEditingId(travelPackage.id);
    setShowModal(true);
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${url}/api/package/delete/${id}`);
      fetchPackages();
    } catch (error) {
      console.error("Error deleting package:", error);
    }
  };

  return (
    <Container className="mt-5">
      <h3>Travel Package Management</h3>
      <Button
        variant="primary"
        onClick={() => setShowModal(true)}
        className="mb-4"
      >
        Add Package
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Destinations</th>
            <th>Package Type</th>
            <th>Place</th>
            <th>Amount per Couple</th>
            <th>Amount per Adult</th>
            <th>Amount per Child</th>
            <th>Duration</th>
            <th>Overview</th>
            <th>Itinerary</th>
            <th>Inclusion</th>
            <th>Exclusion</th>
            <th>Pickup</th> {/* Added column */}
            <th>Drop</th> {/* Added column */}
            <th>Spot</th> {/* Added column */}
            <th>Avg Rating</th> {/* Added column */}
            {/* <th>Photos</th> */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {packages.map((pkg) => (
            <tr key={pkg.id}>
              <td>{pkg.title}</td>
              <td>{pkg.destinations}</td>
              <td>{pkg.packageType}</td>
              <td>{pkg.place}</td>
              <td>{pkg.amountPerCouple}</td>
              <td>{pkg.amountPerAdult}</td>
              <td>{pkg.amountPerChild}</td>
              <td>{pkg.duration}</td>
              <td>{(pkg.overview || "").slice(0, 50)}...</td>{" "}
              {/* Safely handle null */}
              <td>{(pkg.itinerary || "").slice(0, 50)}...</td>{" "}
              {/* Safely handle null */}
              <td>{(pkg.inclusion || "").slice(0, 50)}...</td>{" "}
              {/* Safely handle null */}
              <td>{(pkg.exclusion || "").slice(0, 50)}...</td>{" "}
              {/* Safely handle null */}
              <td>{pkg.pickupPoint}</td>
              <td>{pkg.dropPoint}</td>
              <td>{pkg.spotType}</td>
              <td>{pkg.avgRating}</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleEdit(pkg)}
                  className="me-2"
                >
                  Edit
                </Button>
                <Button variant="danger" onClick={() => handleDelete(pkg.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Add/Edit Travel Package */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {editingId ? "Edit Package" : "Add Package"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* Existing Fields */}
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDestinations" className="mt-3">
              <Form.Label>Destinations</Form.Label>
              <Form.Control
                type="text"
                name="destinations"
                value={formData.destinations}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* <Form.Group controlId="formPackageType" className="mt-3">
              <Form.Label>Package Type</Form.Label>
              <Form.Control
                type="text"
                name="packageType"
                value={formData.packageType}
                onChange={handleChange}
                required
              />
            </Form.Group> */}

            <Form.Group controlId="formPlace" className="mt-3">
              <Form.Label>Place</Form.Label>
              <Form.Control
                type="text"
                name="place"
                value={formData.place}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAmountPerCouple" className="mt-3">
              <Form.Label>Amount per Couple</Form.Label>
              <Form.Control
                type="number"
                name="amountPerCouple"
                value={formData.amountPerCouple}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAmountPerAdult" className="mt-3">
              <Form.Label>Amount per Adult</Form.Label>
              <Form.Control
                type="number"
                name="amountPerAdult"
                value={formData.amountPerAdult}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAmountPerChild" className="mt-3">
              <Form.Label>Amount per Child</Form.Label>
              <Form.Control
                type="number"
                name="amountPerChild"
                value={formData.amountPerChild}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDuration" className="mt-3">
              <Form.Label>Duration</Form.Label>
              <Form.Control
                type="text"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formOverview" className="mt-3">
              <Form.Label>Overview</Form.Label>
              <Form.Control
                as="textarea"
                name="overview"
                value={formData.overview}
                onChange={handleChange}
                rows={3}
                required
              />
            </Form.Group>

            <Form.Group controlId="formItinerary" className="mt-3">
              <Form.Label>Itinerary</Form.Label>
              <Form.Control
                as="textarea"
                name="itinerary"
                value={formData.itinerary}
                onChange={handleChange}
                rows={3}
                required
              />
            </Form.Group>

            <Form.Group controlId="formInclusion" className="mt-3">
              <Form.Label>Inclusion</Form.Label>
              <Form.Control
                as="textarea"
                name="inclusion"
                value={formData.inclusion}
                onChange={handleChange}
                rows={2}
                required
              />
            </Form.Group>

            <Form.Group controlId="formExclusion" className="mt-3">
              <Form.Label>Exclusion</Form.Label>
              <Form.Control
                as="textarea"
                name="exclusion"
                value={formData.exclusion}
                onChange={handleChange}
                rows={2}
                required
              />
            </Form.Group>

            {/* New Fields */}
            <Form.Group controlId="formPickup" className="mt-3">
              <Form.Label>Pickup</Form.Label>
              <Form.Control
                type="text"
                name="pickupPoint"
                value={formData.pickupPoint}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDrop" className="mt-3">
              <Form.Label>Drop</Form.Label>
              <Form.Control
                type="text"
                name="dropPoint"
                value={formData.dropPoint}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formSpot" className="mt-3">
              <Form.Label>Spot</Form.Label>
              <Form.Control
                type="text"
                name="spotType"
                value={formData.spotType}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAvgRating" className="mt-3">
              <Form.Label>Average Rating</Form.Label>
              <Form.Control
                type="number"
                name="avgRating"
                value={formData.avgRating}
                onChange={handleChange}
                step="0.1"
                min="0"
                max="5"
                required
              />
              <Form.Text className="text-muted">
                Enter a value between 0 and 5.
              </Form.Text>
            </Form.Group>

            {/* Photo Upload */}
            <Form.Group controlId="formPhotos" className="mt-3">
              <Form.Label>Photos</Form.Label>
              <Form.Control
                type="file"
                name="photos"
                multiple
                onChange={handleFileChange}
              />
            </Form.Group>

            {/* Form Buttons */}
            <Button variant="primary" type="submit" className="mt-3">
              {editingId ? "Update" : "Submit"}
            </Button>
            <Button
              variant="secondary"
              onClick={clearForm}
              className="mt-3 ms-2"
            >
              Clear
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default TravelPackageAdmin;
