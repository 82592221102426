import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import Blogs from "./pages/Blogs";
import AboutUs from "./pages/AboutUs";
import MainBanner from "./pages/Banners/MainBanner";
import CoralBanner from "./pages/Banners/CoralBanner";
import HoneymoonBanner from "./pages/Banners/HoneymoonBanner";
import AdvertisementOne from "./pages/Banners/AdvertisementOne";
import BlogDetail from "./pages/BlogDetail";
import Header from "./components/Header";
import Login from "./pages/Login";
import PackagesCard from "./pages/Packages/PackagesCard";
import GetCallback from "./pages/GetCallback";
import TravelPackageForm from "./pages/Packages/TravelPackageForm";

function App() {
  const [token, setToken] = useState(false);

  // This effect runs only once when the component mounts
  useEffect(() => {
    const savedToken = localStorage.getItem("token");
    setToken(!!savedToken); // Set token to true if it exists
    console.log(!!savedToken); // Log the existence of the token
  }, []); // Empty dependency array to run this effect only once

  // If there is no token, render the Login component
  if (!token) {
    return <Login />;
  } else {
    // If there is a token, render the main application
    return (
      <div>
        <Header />
        <Container fluid className="d-flex">
          <Sidebar />
          <Container fluid className="p-4">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/" element={<Login />} />
              <Route path="/banner/main" element={<MainBanner />} />
              <Route path="/banner/honeymoon" element={<HoneymoonBanner />} />
              <Route path="/banner/coral" element={<CoralBanner />} />
              <Route
                path="/banner/advertisement"
                element={<AdvertisementOne />}
              />
              <Route path="/package/packagecard" element={<PackagesCard />} />
              <Route
                path="/package/packagedetail"
                element={<TravelPackageForm />}
              />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogdetail" element={<BlogDetail />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/callback" element={<GetCallback />} />
            </Routes>
          </Container>
        </Container>
      </div>
    );
  }
}

export default App;
