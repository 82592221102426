import axios from "axios";
import { useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Row,
} from "react-bootstrap";
// import { LoginAnimation } from "../about/loginAnimation";
import { Link } from "react-router-dom";
import "../Styles/auth.css";
import { LoginAnimation } from "../components/LoginAnimation";
// import Link from "next/link";
// import { loginApi } from "../api/apis";
// import { useRouter } from "next/router";
const url = process.env.REACT_APP_URL;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    // Create a data object containing the email and password
    let data = { email, password };

    try {
      // Sending a POST request to the login API
      let res = await axios.post(`${url}/api/adminlogin`, data, {
        headers: {
          "Content-Type": "application/json", // Ensure headers are set correctly
        },
      });

      // Optionally handle the response (e.g., store the token, redirect, etc.)
      if (res.data.token) {
        localStorage.setItem("token", res.data.token); // Store the token in localStorage
        // Redirect or handle login success logic
        window.location.href = "/";
      }
    } catch (error) {
      // Log any error that occurs during the request
      console.error(
        "Login error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <>
      <LoginAnimation />
      <div className="auth-part">
        <Container className="auth-container">
          <Row sm={1} md={2} lg={2}>
            <Col>
              <div className="auth-logo-part">
                <Link href="/">
                  <Image
                    src="/assets/logo.png"
                    alt="logo"
                    className="auth-logo"
                  ></Image>
                </Link>
              </div>
              <div className="auth-left">
                <Image
                  src="/assets/loginBanner.webp"
                  alt="loginAnimation"
                  className="login-banner"
                ></Image>
              </div>
            </Col>
            <Col className="auth-col-two">
              <div className="auth-right">
                <div className="auth-header">
                  <h2 className="login-label">
                    Sign in to customize your travel experience
                  </h2>
                  <hr className="auth-hr" />
                </div>

                <Form className="auth-form" onSubmit={handleLogin}>
                  <FloatingLabel
                    controlId="floatingEmail"
                    label="Email address"
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ width: "100%" }}
                      className="auth-form"
                    />
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Password"
                    className="mb-3"
                  >
                    <Form.Control
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ width: "100%" }}
                      className="auth-form"
                    />
                    <span className="password-icon"></span>
                  </FloatingLabel>

                  <div
                    className="error-message"
                    style={{ color: "red", marginBottom: "10px" }}
                  ></div>

                  <div
                    className="success-message"
                    style={{ color: "green", marginBottom: "10px" }}
                  ></div>

                  <Form.Group as={Row} className="auth-btn">
                    <Col>
                      <Button type="submit" className="submit-btn">
                        Sign in
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
