import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import "../Styles/about.css"; // Import the custom CSS
const url = process.env.REACT_APP_URL;

function AboutUs() {
  const [formData, setFormData] = useState({
    name: "",
    position: "",
    team: "",
    description: "",
    image: null,
  });

  const [dataList, setDataList] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  console.log(url);
  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}/api/team/get`);
      console.log("----->", response);
      setDataList(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", formData.name);
    data.append("position", formData.position);
    data.append("team", formData.team);
    data.append("description", formData.description);
    data.append("image", formData.image);

    try {
      if (editingId) {
        await axios.put(`${url}/api/team/update/${editingId}`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        await axios.post(`${url}/api/team/post`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
      fetchData();
      clearForm();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    setShowForm(false);
  };

  const clearForm = () => {
    setFormData({
      name: "",
      position: "",
      team: "",
      description: "",
      image: null,
    });
    setEditingId(null);
  };

  const handleEdit = (item) => {
    setFormData({
      name: item.name,
      position: item.position,
      team: item.team,
      description: item.description,
      image: null,
    });
    setEditingId(item.id);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${url}/api/team/delete/${id}`);
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={10}>
          <h3>Team Management</h3>
          <Button
            variant="primary"
            onClick={() => setShowForm(true)}
            className="mb-4"
          >
            Add Employee
          </Button>

          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Position</th>
                <th>Team</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dataList.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.position}</td>
                  <td>{item.team}</td>
                  <td>{item.description}</td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <Button
                        variant="warning"
                        onClick={() => handleEdit(item)}
                        className="me-2"
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Modal show={showForm} onHide={() => setShowForm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingId ? "Edit Employee" : "Add Employee"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter name"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPosition" className="mt-3">
              <Form.Label>Position</Form.Label>
              <Form.Control
                type="text"
                name="position"
                value={formData.position}
                onChange={handleChange}
                placeholder="Enter position"
                required
              />
            </Form.Group>

            <Form.Group controlId="formTeam" className="mt-3">
              <Form.Label>Team</Form.Label>
              <Form.Control
                type="text"
                name="team"
                value={formData.team}
                onChange={handleChange}
                placeholder="Enter team"
                required
              />
            </Form.Group>

            <Form.Group controlId="formDescription" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleChange}
                rows={3}
                placeholder="Enter description"
                required
              />
            </Form.Group>

            <Form.Group controlId="formImage" className="mt-3">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleFileChange}
                accept="image/*"
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              {editingId ? "Update" : "Submit"}
            </Button>
            <Button
              variant="secondary"
              onClick={clearForm}
              className="mt-3 ms-2"
            >
              Clear
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default AboutUs;
