// components/Sidebar.js
import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaChevronDown,
  FaChevronUp,
  FaSuitcase,
  FaBlog,
  FaInfoCircle,
} from "react-icons/fa";
import "../Styles/sidebar.css";

const Sidebar = () => {
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [isPackageOpen, setIsPackageOpen] = useState(false);

  const toggleBannerLinks = () => {
    setIsBannerOpen(!isBannerOpen);
  };

  const togglePackageLinks = () => {
    setIsPackageOpen(!isPackageOpen);
  };

  return (
    <div className="sidebar bg-#bfc0ce text-dark vh-100 p-3">
      <Nav defaultActiveKey="/" className="flex-column">
        <Nav.Link as={Link} to="/" className="text-dark">
          <FaHome className="me-2" /> Dashboard
        </Nav.Link>

        <div>
          <Nav.Link
            as={Link}
            to="/"
            className="text-dark"
            onClick={toggleBannerLinks}
          >
            <FaHome className="me-2" /> Banners{" "}
            {isBannerOpen ? <FaChevronUp /> : <FaChevronDown />}
          </Nav.Link>
          {isBannerOpen && (
            <>
              <Nav.Link as={Link} to="/banner/main" className="text-dark ms-4">
                Main Banner
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/banner/honeymoon"
                className="text-dark ms-4"
              >
                Honeymoon Banner
              </Nav.Link>
              <Nav.Link as={Link} to="/banner/coral" className="text-dark ms-4">
                Coral Banner
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/banner/advertisement"
                className="text-dark ms-4"
              >
                Advertisement Banner
              </Nav.Link>
            </>
          )}
        </div>

        <div>
          <Nav.Link
            as={Link}
            to="/"
            className="text-dark"
            onClick={togglePackageLinks}
          >
            <FaSuitcase className="me-2" /> Packages{" "}
            {isPackageOpen ? <FaChevronUp /> : <FaChevronDown />}
          </Nav.Link>
          {isPackageOpen && (
            <>
              <Nav.Link
                as={Link}
                to="/package/packagecard"
                className="text-dark ms-4"
              >
                Package Card
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/package/packagedetail"
                className="text-dark ms-4"
              >
                Package Detail
              </Nav.Link>
            </>
          )}
        </div>

        <Nav.Link as={Link} to="/blogs" className="text-dark">
          <FaBlog className="me-2" /> Blogs
        </Nav.Link>
        <Nav.Link as={Link} to="/blogdetail" className="text-dark">
          <FaBlog className="me-2" /> Blog Detail
        </Nav.Link>
        <Nav.Link as={Link} to="/about" className="text-dark">
          <FaInfoCircle className="me-2" /> About Us
        </Nav.Link>
        <Nav.Link as={Link} to="/callback" className="text-dark">
          <FaInfoCircle className="me-2" /> Get Callback
        </Nav.Link>
      </Nav>
    </div>
  );
};

export default Sidebar;
