import React, { useState, useEffect } from "react";
import { Form, Button, Container, Table, Modal } from "react-bootstrap";
import axios from "axios";
const url = process.env.REACT_APP_URL;

const AdvertisementOne = () => {
  const [advertisement, setAdvertisement] = useState([]);
  const [formData, setFormData] = useState({
    destination: "",
    destination_type: "",
    amount: "",
    image: null,
  });
  const [editingId, setEditingId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch all advertisement on component mount
  useEffect(() => {
    fetchadvertisement();
  }, []);

  // Fetch all advertisement
  const fetchadvertisement = async () => {
    try {
      const response = await axios.get(`${url}/api/advertisement/get`);
      setAdvertisement(response.data);
    } catch (error) {
      console.error("Error fetching advertisement:", error);
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle image upload
  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };

  // Submit form (Create/Update)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("destination", formData.destination);
    data.append("destination_type", formData.destination_type);
    data.append("amount", formData.amount);
    if (formData.image) data.append("image", formData.image);

    try {
      if (editingId) {
        await axios.put(`${url}/api/advertisement/update/${editingId}`, data);
      } else {
        await axios.post(`${url}/api/advertisement/post`, data);
      }
      fetchadvertisement();
      clearForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setShowModal(false);
  };

  // Clear form and reset state
  const clearForm = () => {
    setFormData({
      destination: "",
      destination_type: "",
      amount: "",
      image: null,
    });
    setEditingId(null);
  };

  // Handle edit
  const handleEdit = (blog) => {
    setFormData({
      destination: blog.destination,
      destination_type: blog.destination_type,
      amount: blog.amount,
      image: null,
    });
    setEditingId(blog.id);
    setShowModal(true);
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${url}/api/advertisement/delete/${id}`);
      fetchadvertisement();
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  return (
    <Container className="mt-5">
      <h3>Advertisement Management</h3>
      <Button
        variant="primary"
        onClick={() => setShowModal(true)}
        className="mb-4"
      >
        Add Advertisement
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Destination</th>
            <th>Destination Type</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {advertisement.map((blog) => (
            <tr key={blog.id}>
              <td>{blog.destination}</td>
              <td>{blog.destination_type}</td>
              <td>{blog.amount}</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleEdit(blog)}
                  className="me-2"
                >
                  Edit
                </Button>
                <Button variant="danger" onClick={() => handleDelete(blog.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Add/Edit Advertisement */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingId ? "Edit Advertisement" : "Add Advertisement"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formDestination">
              <Form.Label>Destination</Form.Label>
              <Form.Control
                type="text"
                name="destination"
                value={formData.destination}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDestinationType" className="mt-3">
              <Form.Label>Destination Type</Form.Label>
              <Form.Control
                type="text"
                name="destination_type"
                value={formData.destination_type}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAmount" className="mt-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formImage" className="mt-3">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleFileChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              {editingId ? "Update" : "Submit"}
            </Button>
            <Button
              variant="secondary"
              onClick={clearForm}
              className="mt-3 ms-2"
            >
              Clear
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AdvertisementOne;
