// pages/Dashboard.js
import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2"; // Assuming chart.js for professional charts
import {
    Chart as ChartJS,
    CategoryScale,   // Register the Category scale
    LinearScale,     // Register the Linear scale for Y axis
    PointElement,    // Points in the line chart
    LineElement,     // The line itself
    Title,
    Tooltip,
    Legend
  } from 'chart.js';
  
  // Register required components
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const Dashboard = () => {
  let token = localStorage.getItem("token");
  const chartData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May"],
    datasets: [
      {
        label: "Package Sales",
        data: [50, 70, 100, 120, 150],
        fill: false,
        backgroundColor: "#007bff",
        borderColor: "#007bff",
      },
    ],
  };

  return (
    <Container fluid>
      <h1 className="my-4">Dashboard</h1>
      <Row>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Total Packages</Card.Title>
              <h3>150</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Total Blog Posts</Card.Title>
              <h3>75</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Active Users</Card.Title>
              <h3>1,200</h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Package Sales Over Time</Card.Title>
              <Line data={chartData} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
