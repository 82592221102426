import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Modal,
} from "react-bootstrap";
import axios from "axios";
// import "../Styles/about.css"; // Import the custom CSS
const url = process.env.REACT_APP_URL;

function HoneymoonBanner() {
  const [formData, setFormData] = useState({
    content1: "",
    content2: "",
    video: null,
    image: null,
  });

  const [dataList, setDataList] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${url}/api/honeymoon/get`
      );
      setDataList(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { title, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [title]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      video: e.target.files[0],
    }));
  };

  const handleFileChange1 = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("content1", formData.content1);
    data.append("content2", formData.content2);
    data.append("video", formData.video);
    data.append("image", formData.image);

    try {
      if (editingId) {
        await axios.put(
          `${url}/api/honeymoon/update/${editingId}`,
          data,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      } else {
        await axios.post(`${url}/api/honeymoon/post`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
      fetchData();
      clearForm();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    setShowForm(false);
  };

  const clearForm = () => {
    setFormData({
      content1: "",
      content2: "",
      video: null,
      image: null,
    });
    setEditingId(null);
  };

  const handleEdit = (item) => {
    setFormData({
      content1: item.content1,
      content2: item.content2,
      video: null,
      image: null,
    });
    setEditingId(item.id);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${url}/api/honeymoon/delete/${id}`);
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <Container classtitle="mt-5">
      <Row classtitle="justify-content-md-center">
        <Col md={10}>
          <h3>honeymoon</h3>
          <Button
            variant="primary"
            onClick={() => setShowForm(true)}
            classtitle="mb-4"
          >
            Add honeymoon
          </Button>

          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Content 1</th>
                <th>Content 2</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dataList.map((item) => (
                <tr key={item.id}>
                  <td>{item.content1}</td>
                  <td>{item.content2}</td>
                  <td>
                    <div classtitle="d-flex justify-content-between">
                      <Button
                        variant="warning"
                        onClick={() => handleEdit(item)}
                        classtitle="me-2"
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(item.id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Modal show={showForm} onHide={() => setShowForm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingId ? "Edit honeymoon" : "Add honeymoon"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formtitle">
              <Form.Label>Content 1</Form.Label>
              <Form.Control
                type="text"
                title="content1"
                value={formData.content1}
                onChange={handleChange}
                placeholder="Enter content 1"
                required
              />
            </Form.Group>

            <Form.Group controlId="formDescription" classtitle="mt-3">
              <Form.Label>Content 2</Form.Label>
              <Form.Control
                type="text"
                title="content2"
                value={formData.content2}
                onChange={handleChange}
                placeholder="Enter content 2"
                required
              />
            </Form.Group>

            <Form.Group controlId="formvideo" classtitle="mt-3">
              <Form.Label>video</Form.Label>
              <Form.Control
                type="file"
                title="video"
                onChange={handleFileChange}
                accept="video/*"
              />
            </Form.Group>

            <Form.Group controlId="formImage" className="mt-3">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleFileChange1}
                accept="image/*"
              />
            </Form.Group>

            <Button variant="primary" type="submit" classtitle="mt-3">
              {editingId ? "Update" : "Submit"}
            </Button>
            <Button
              variant="secondary"
              onClick={clearForm}
              classtitle="mt-3 ms-2"
            >
              Clear
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default HoneymoonBanner;
